import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/me/aaa/github/personalsite/src/components/post_template.js";
import ImageSource from "src/components/image";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Uprise`}</h1>
    <p>{`Developed a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Health_Insurance_Portability_and_Accountability_Act"
      }}>{`HIPAA`}</a>{`-compliant dashboard app for the client (based in the United States) to be able to:`}</p>
    <ul>
      <li parentName="ul">{`upload insurance claims (in the form of `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/ASC_X12"
        }}>{`X12 EDI files`}</a>{`),`}</li>
      <li parentName="ul">{`and analyze the `}<strong parentName="li">{`root cause(s)`}</strong>{` of medical billing denials/rejections `}<small>{`(i.e. the refusal of an insurance company or carrier to honor a request by an individual (or his or her provider) to pay for healthcare services obtained from a healthcare professional)`}</small>{`.`}</li>
    </ul>
    <p>{`The app enabled the client to consult clinics, hospitals, and healthcare practitioners to recover rejected payments from insurance companies.`}</p>
    {
      /*
      https://electronichealthreporter.com/differences-between-a-rejection-and-denial-in-medical-billing/
      */
    }
    <br />
    <p><strong parentName="p">{`Frontend`}</strong></p>
    <ul>
      <li parentName="ul">{`React.js`}</li>
      <li parentName="ul">{`styled-components (CSS-in-JS)`}</li>
      <li parentName="ul">{`Redux`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://flow.org/"
        }}>{`flow`}</a>{` (static type checking)`}</li>
    </ul>
    <p><strong parentName="p">{`Backend`}</strong></p>
    <ul>
      <li parentName="ul">{`Node.js`}</li>
      <li parentName="ul">{`express`}</li>
      <li parentName="ul">{`MySQL`}</li>
      <li parentName="ul">{`Google Cloud Platform`}</li>
    </ul>
    <br />
    <hr />
    <br />
    <ImageSource src="uprise/login.png" alt="Login page" mdxType="ImageSource" />
    <br />
    <ImageSource src="uprise/overview.png" alt="Overview page" mdxType="ImageSource" />
    <br />
    <ImageSource src="uprise/overview_2.png" alt="Overview page" mdxType="ImageSource" />
    <br />
    <ImageSource src="uprise/files.png" alt="Files page" mdxType="ImageSource" />
    <br />
    <ImageSource src="uprise/upload.png" alt="Upload modal" mdxType="ImageSource" />
    <br />
    <ImageSource src="uprise/upload_2.png" alt="Upload modal" mdxType="ImageSource" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      